import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false


// 將 Bootstrap 和 BootstrapVue 的 CSS 引入到專案中
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import  bootstrap from 'bootstrap';

Vue.prototype.$apiUrl = '';

import VueLazyload from 'vue-lazyload';

// 使用 VueLazyload，可以在这里配置选项
Vue.use(VueLazyload, {
  preLoad: 2,
  error: '/assets/loading.gif',   // 加载错误时的图片
  loading: '/assets/loading.gif',  // 加载中的占位图片
  attempt: 2 // 尝试加载图片的次数
})



new Vue({
  router,
  store,
  bootstrap,
  render: h => h(App)
}).$mount('#app')
